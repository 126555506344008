import { HexagramMessageMap, HexagramType } from './type';

export const hexagramMessagesMap: Record<HexagramType, HexagramMessageMap> = {
  乾: {
    主要: '表示極陽之象，為純卦、六沖卦，是大好、大壞的卦象。對事一般為吉象，為主管、負責任，可以任用擔當大事，或開業當老闆，得此卦都屬吉象，只是必須有一個好的助理更佳，能升官、考試、升遷都較有利。',
    失物: '須一段時間，在西北方可以找到。',
    子女: '兒女健康，幸福有為。',
    家運: '幸運興隆，須小心口舌爭論之象。',
    尋人: '已遠走高飛，不易找到，在西北方向。',
    愛情: '彼此之間切勿逞強，摒除自我主義的觀念方可成就，但常有鬧意見之情況。',
    改行: '改行者不宜。',
    旅行: '夏季不宜，勿行南、北，其他可。',
    求事求職: '有貴人提拔，可發展，稱心。',
    特性: '積極，剛毅，努力，認真，有耐性，堅忍，正派，主動，慷慨，嚴律，重名譽。',
    疾病: '為頭部或神經之疾，宜細心調養。',
    等人: '一定會來。',
    考試: '有望。',
    胎孕: '有不安之兆，宜小心保養。',
    解釋: '剛健穩固。',
    訟詞糾紛: '有理，據理則吉。',
    買賣: '小利可得，大則勿取，勿太張揚為吉。',
    週轉: '雖不成問題，尚宜心平氣和來處理。戌亥日可。',
    運勢: '有地位擢升、名利雙收之象，功名榮顯得貴子。六親和睦，凡事謙和為貴，可吉。',
  },
  坤: {
    主要: '表大地為母，天生之母性也。六沖純卦。主大好大壞之卦象。優點',
    失物: '西南可尋，遺失西北難尋。',
    子女: '兒女眾多，和睦幸福也。',
    家運: '幸福平靜之象也。',
    尋人: '此人為色情之事出走，於西南方附近之地也。',
    愛情: '男主動，女主靜，緩進可成，急則有失。',
    改行: '改行不利，宜固守本份。開業者再觀望，目前尚未籌備妥當，不宜。',
    旅行: '不宜。更忌東北方。',
    求事求職: '不稱意亦不理想，另尋。',
    特性: '為人較為保守，善於計畫與研究工作，適合任輔佐幕僚之職，做人樸實，人緣佳，大好人型，男性桃花多，女性則愛情專一。',
    疾病: '為腹部之慢性病，病情重且危。',
    等人: '不會來，須再待些時日。',
    考試: '希望不大。',
    胎孕: '生貴子。女兒亦福。',
    解釋: '在母胎裡孕育中，不宜創業',
    訟詞糾紛: '多因田土之是非，宜解。有始無終之兆也。',
    買賣: '勿急。交易可成。',
    週轉: '須耐心去求，否則難成。',
    運勢: '諸事不宜急進，退守以靜制動者吉。坤者大地平靜，任其他物所摧而不為所動者大利也。若醉心私慾，違背常理，則有無情之災。',
  },
  屯: {
    主要: '表剛出生的小草。主凶象。四大難卦第一卦。因為剛出生的小草，脆弱易折，表示凡事都會很困難，很容易就會夭折、失敗。但是只要有心小心地護持，小草還是有機會長成大樹的！',
    失物: '若非急尋，皆不得見也。',
    子女: '有辛苦，但以後可得安逸。',
    家運: '多波折，若能努力排除困難，可得通順。',
    尋人: '出走者不知去向，難尋。東或北方去矣。',
    愛情: '不妥協，或已暫時失去連絡，否則就是出現不良狀況而有所阻礙，成就有困難也。而此卦男人得利，女人失利。',
    改行: '改行不宜。開業不宜，有困難亦難成也。',
    旅行: '不宜。',
    求事求職: '不利。',
    特性: '理想遠大，奮鬥不懈，苦盡甘來，最後能達成目標，獲得他人的肯定。一生困境多，煩惱厭煩多，朋友少助。',
    疾病: '為腎臟部位或神經系統之疾病。病或傷在手足，占病大致危險。苦二爻三爻變時，即顯凶兆。',
    等人: '不會來。',
    考試: '不理想。',
    胎孕: '不安，產母有虛驚，但可無慮。',
    解釋: '不明不白',
    訟詞糾紛: '小事成忌而粘滯，大事反不為大吉。諸事宜解。',
    買賣: '不利，交易有損。',
    週轉: '困難重重，須用心忍耐奔波，但仍不看好。',
    運勢: '逆勢，多有困惑，但不可妄進，宜守不宜進。堅定意志，凡事不可為一己之利而執意強行，皆有不成損破之象。宜步步為營，排除困難，始可通達。有初難後解之象。',
  },
  蒙: {
    主要: '表被蒙蔽不明之意。主小凶象。事情迷濛不明，不宜輕信於人，此卦易犯小人，需誠心求教於有智慧經驗的友人長者，受其啟蒙開智慧，方能轉危而安。若是一意而行必現大凶象。',
    失物: '難尋得。',
    子女: '應注重子女教養，將來方得幸福之象。',
    家運: '初時辛苦多端，上下無法溝通，只要以誠相待，必有開運之時。',
    尋人: '受誘惑而出走，因本身蒙昧無知而受騙。在東北或北方，日前難尋。',
    愛情: '此卦為未啟蒙之卦，致落花有意而流水無情。主要是缺乏勇氣與決心，致不成事也。若有成，此卦亦主勞碌耳。',
    改行: '改行不宜。開業者不宜。再等一段時間，籌備好資金再做打算。',
    旅行: '不利。',
    求事求職: '未能如願，再等時機。',
    特性: '喜歡追求新的知識，過平穩生活，做事謹慎，對下屬要求較嚴，領導統御略差。宜防受朋友之拖累蒙蔽。',
    疾病: '由得此卦幼時多病，中年後漸強壯。占病者，病情不明，且為反覆之病症。為脾膽、腸炎症或下腹疾病。急無功，須長治。',
    等人: '可能走錯路，會遲到。',
    考試: '不理想。',
    胎孕: '胎孕無礙。',
    解釋: '被蒙蔽、被欺瞞 。',
    訟詞糾紛: '因外人引起，目下末見明白，有理亦講不清，故有凶象。久而不成。',
    運勢: '氣運不發，多迷惑。初時不順，須忍耐待機，漸可佳。切勿貿然行事或為人認保、作保，否則必有損失。凡事宜從上輩教誨，則運可通也。',
  },
  需: {
    主要: '表需要、需求，但卻又是時機未到而著急著。此卦凶中帶吉，需耐心等待，則事情可成。健康，要特別注意，此卦為「遊魂卦」。',
    失物: '因喜樂中有失，隔些時日可尋，急亦難如。',
    子女: '得子遲。',
    家運: '初為多事之秋，須憑智慧耐心挽救頹勢。',
    尋人: '此出走之人因感情之事，於西北或北方。',
    愛情: '起初不如意，有耐心者方可成就，否則不成也。',
    改行: '改行不宜。開業亦不宜。',
    旅行: '不可。有盜難之兆。',
    求事求職: '不宜急取，急亦不得，再等待，再尋求。',
    特性: '謙恭有禮，性格保守，稍被動，但為人誠懇，慾求不高，中晚年才漸入佳境。不利早婚，有宗教藝術興趣。',
    疾病: '為頭部、腎部之疾，病情拖延而長。須留心治療，長久病疾也。',
    等人: '遲到。',
    考試: '要多用功。',
    胎孕: '臨產之時得才占卜表會有障礙。防剋產母。',
    解釋: '需要等待時機 。',
    訟詞糾紛: '目下未了，但最好停止紛爭。',
    買賣: '有口舌不可成就，亦勿貪小而失大。',
    週轉: '不能預期而得，有延滯。',
    運勢: '宜退守正道，不宜冒險前進，投機急取反而失利。須知貪小失大，智者必須待時也。',
  },
  訟: {
    主要: '表訴訟、爭端之象。主大凶象。事情衝突必須到見官上法院，可能必須以法律途徑來解決了！凡事皆不利，而且已引起很大口舌是非爭持，而必須攤牌互相攻擊的程度了！',
    失物: '因左右之人而失，不必找了。即使找到，亦必有爭端，不如破財消災。',
    子女: '父子之間存有歧見，無法溝通之象。子女生來勞碌，與父母緣薄，多辛勞憂苦也。',
    家運: '家庭內部不和，有口舌爭端之害，並有散離之象，力持仁與和，運破敗，離散之凶象也。',
    尋人: '此人因爭執事由負氣出走，並有生命危險，又尋找不易，儘量往西北與北方尋找。',
    愛情: '多事之時，是非頻頻，受中傷不易成也。',
    改行: '改行不利，開業不宜。',
    旅行: '不宜。',
    求事求職: '難成。',
    特性: '不服輸，好競爭辯論，反應快，口才佳，易得罪他人。自立自強之人。',
    疾病: '除了儘量醫治外別無他法。所患為腦部、外傷頭部、腎臟與血液循環系統之疾病，多病之象。',
    等人: '不會來。就算會來，雙方必有爭執情事發生。',
    考試: '不理想。很差。',
    胎孕: '生產無驚阻，安也。',
    解釋: '官司纏訟。',
    訟詞糾紛: '不利。兩敗俱傷。最好由中間人調解，再爭無益也。',
    買賣: '因處理不當，有衝突爭端，常招損失，不利也。',
    週轉: '不得要領，難成。',
    運勢: '百事閉塞不通，傷害頻繁，受中傷而又易陷於奸計，不如意之運也。得饒人處且饒人，得過且過，莫再爭訟，否則皆不利也。',
  },
  師: {
    主要: '表軍隊打仗強烈抗爭之意。主大凶象。師亦為「師長、老師」，是為強烈教訓的現象，若在事、在人，均表示會受到很大教訓、挫折。或是與人結怨甚深而遭到其強力的打擊、迫害。',
    失物: '已被竊走。',
    子女: '大出息之象。',
    家運: '外柔順而內險惡，故家內常生不和，需慎重處理。行正道可化險為安。',
    尋人: '此人因家內失和而出走，速往北方或西南找，否則有危險。',
    愛情: '慎防感情引起的衝突、傷害，難再有成。',
    改行: '改行不宜。開業者須待時機行事，勿過急。',
    旅行: '不利。防有盜失。',
    求事求職: '獨立無望，有人扶持則可。',
    特性: '講求實力，具有毅力，不怕困難，挑戰艱鉅任務，雖然時常會遇到逆境，都能一一化解克服，贏得他人的讚許。',
    疾病: '為腹部之惡性腫瘤，非絕症。若超年久病即有活解。',
    等人: '來者不善。',
    考試: '很認真，但不理想，繼續努力終有成就。',
    胎孕: '喜中有憂。或有不安或不孕。',
    解釋: '但要師出有名。',
    訟詞糾紛:
      '有貴人為福，不宜退，他人無氣有憂危，終有利。但形成硬碰硬，多因之局面也。',
    買賣: '有阻，不太順意，寅午戌日交易可成。',
    週轉: '無望。另覓別處。',
    運勢: '事雖有不順、但要引以為鏡，記取教訓。不要圖私利而投機取巧。凡事應以正規而行，事物多變動，切忌獨立而行。困難多端，靠自己努力，摒除一切困難為上策。',
  },
  比: {
    主要: '表兄友比肩而站，一片融合互持、比和之意。主吉象。水溶入土中、膠和在一起，是個很親密的卦象，尤其是論男女之情。事業投資有親密的伙伴相助，共同扶持打拼，事事當然越佳順利進行。',
    失物: '東北、西南方可尋，但有損減。',
    子女: '兒女溫順且侍親至孝，親情融合之象。',
    家運: '家庭內部不和，有口舌爭端之害，並有散離之象，力持仁與和，運破敗，離散之凶象也。',
    尋人: '不在杏花處，則是有婦人相留，不可尋，恐生不測之禍。此人會自己回來。',
    愛情: '閤家和樂，家運亨通。宜處事有序使運吉。',
    改行: '改行有利，可得友人支持。開業者可大發利市，但須注意有人背叛。',
    旅行: '吉利。',
    求事求職: '有貴人，可有利。',
    特性: '勤勞，認真，善良，謙恭，務實，踏實，重生活享受，品味人生，安排娛樂。異性緣多，感情煩惱也多。',
    疾病: '為腹部反耳痛之症，或腎臟下部之疾，近病者速醫可治。',
    等人: '會來。',
    考試: '金榜題名。',
    胎孕: '孕安。',
    解釋: '平等、比合。',
    訟詞糾紛: '宜和，有小人。以和順態度處理可調。',
    買賣: '莫太貪心。交易反覆難成，勿貪則有利可成。',
    週轉: '必成、找同年之之兄弟、朋友可成。',
    運勢: '運氣平順，與人相親處事則吉。可獲貴人提拔，但不可得志而非份橫行急進，否則遭破遜也。',
  },
  小畜: {
    主要: '表小有積蓄。是個小吉卦，事事不可貪大，投資理財均需保守為佳。健康無礙平順。',
    特性: '上進心，求知慾強，思想靈敏，察言觀色，善解人意，追求精神層次，美好事物。有藝術美學方面天分。',
    解釋: '稍有積蓄。',
    運勢: '有蓄聚、阻止之意。一陰畜五陽，雖有雲雨而不下，雨之不下，大地不能得到滋潤，故此運勢有反覆、有口舌、又難以急調，諸事宜忍耐，等待時機。尤其女人得此卦更應在行為上，對事或對人有所節制。',
    愛情: '語言不和，意見分歧，不太如意。',
    家運: '不和且多是非，衰而不振，多勞，要耐心應對，以待時機。雨過天必晴。',
    胎孕: '宜防流產。',
    子女: '因子女而勞碌之象，但晚年有福也。',
    週轉: '有女人從中阻礙，不成也。',
    買賣: '景氣不好，買賣難成或利薄。',
    疾病: '患者有胸部、胃腸等疾。其病難治但不嚴重。',
    等人: '不會來，臨時變意。',
    尋人: '因感情或家庭不和出走，只在原處。東南或西北之向。',
    失物: '被小人侵算，有婦人見得，急向東南方覓之。屋內之失物可尋。',
    旅行: '途中有害，不宜。',
    訟詞糾紛: '一不成亦和。一惹禍帶刑惕，不利。',
    求事求職: '希望渺小。',
    考試: '不理想。',
    改行: '改行不宜，開業不宜，已開業者則困難、口舌多。',
  },
  履: {
    主要: '表戰戰兢兢、如履薄冰而行，卻是有驚無險。為小凶帶吉之象。履又為「禮」也。得此卦者，需盡快反省自己的態度，對人、對事、對長輩是否有輕忽、不敬之意。雖有危急之事幸好能平安度過。',
    特性: '競爭，積極，冒險，熱枕，獨立，自立自強，有骨氣。易給人反感，嫉妒。',
    解釋: '只要履行契約承諾則吉。',
    運勢: '有先勞後逸、始驚後安之意，雖有繁華，卻又反覆不和，故得此卦時為憂慮受危中而望喜渺。但謙虛反省請教於長者則吉。',
    愛情: '相愛，但阻礙很多，雖然不易成功，但亦有以誠、禮相待而成婚姻者。',
    家運: '新婚而剛建立家庭者，多礙、勞苦。但此卦有先苦後甘之象，和氣可生財，過些時日即可雨過天晴也。',
    胎孕: '臨盆產婦占得則吉，否則有受驚嚇之象，需安胎。',
    子女: '初運有勞苦，而且障礙、困難之象，但以後能獲得幸福也。',
    週轉: '雖有些困難，但不必急躁，謙恭而施則可成也。',
    買賣: '雖有是非但終可成，牛馬猴之月或日方成。',
    疾病: '得此卦者，幼年體弱，中年後漸佳。斷疾病為肺部、頭部或口部之病，病況雖似嚴重而危險，但調理得宜，治療後可痊癒。',
    等人: '遲來。',
    尋人: '走失之人有生命危險，在西方或西北方向，難尋，須爭取時效，此人尚可得救。申未日可見。',
    失物: '須一段時間，在西北方可以找到。',
    旅行: '夏季不宜，勿行南、北，其他可。以占卦之時斷之。',
    訟詞糾紛: '有理，據理則吉。',
    求事求職: '有貴人提拔，可發展，稱心。',
    考試: '有望。',
    改行: '改行者不宜。',
  },
  泰: {
    主要: '表三陽開泰，萬事亨通。吉卦之象。諸事皆順。',
    特性: '交遊廣泛，包容性大，有雅量，個性開朗，樂觀，積極，主動。又能接受他人意見，心胸廣闊。',
    解釋: '否極泰來，鴻運當頭。',
    運勢: '諸事如意吉祥，前途事業均順利。切不可驕傲或任意從事，亦宜自惕勿太活躍，始能免於災難。凡事宜求內在之實，不求外在之虛，否則有破。',
    愛情: '情投意合，良緣。勿任性，以維持吉象，反之則招破。',
    家運: '家庭和合，有通亨之象，凡事宜檢點得失，不可胡為，否則招災不利。',
    胎孕: '生貴子。安而無災。生女亦均無礙。',
    子女: '親子和睦，幸福圓滿。留心教養，勿使太任性，否則淪於不幸之破運。',
    週轉: '可成。',
    買賣: '交易有益，得利。',
    疾病: '為胃腸、或重感冒、肺、頭痛之症，無大礙。',
    等人: '會來。',
    尋人: '在朋友或親戚家裡，有信息也。可尋。',
    失物: '非是人偷、自己遺失。有可能尋回之象。',
    旅行: '順利平安。',
    訟詞糾紛: '因小意見而引起，不宜見官，和解有利。',
    求事求職: '吉利亨通。',
    考試: '上榜有望，宜再努力勿懈。',
    改行: '改行吉。開業者吉利之象。',
  },
  否: {
    主要: '表不好、壞掉了！主凶象。否顯現出陰陽之氣不協調，在人及事方面諸多不被認同，有內外不合、眾說紛紜的現象，因此事事難以進行，最好此時能彼此互相退讓靜下心來理性的溝通，方能轉吉。婚姻、感情，已有個性、意見的衝突產生，若不能好好退讓協商，恐有離異分手的結局。',
    特性: '自尊心強，個性剛烈，不服輸，反應快，學習能力強，高傲不亦親近。財運佳，有領導才能，與上司不易相處。',
    解釋: '處在困境中。',
    運勢: '凶且衰，君子以險德避難，不可榮以祿也。邇勢不通達，諸事不順，有損失且波折多。故宜守固正道，若稍偏差，則災害至也。',
    愛情: '陰陽相背，被拒千里之外。',
    家運: '夫妻彼此不能協調，是非很多，劫財又帶衰，慎之。',
    胎孕: '不安，爻神無氣。',
    子女: '與雙親感情不睦。任其自然發展吧。',
    週轉: '告貸無門。甫求了。',
    買賣: '多有損失。但有貴人應在午未申日。',
    疾病: '占病凶兆，康復無望，反象也。病灶為腦部、胃部、癌之類。',
    等人: '不會來，因別的事情而違約。',
    尋人: '因感情不睦而出走，去向不明，難尋。東南方。',
    失物: '熱鬧中遺失，東南方尋之。',
    旅行: '不宜。',
    訟詞糾紛: '有口難言，有虛偽，意見不能妥協，此時宜求順。',
    求事求職: '先難後易，須有耐心去爭取勝利，否則凶。',
    考試: '不理想。',
    改行: '改行難如願，開業最好另擇吉日。然此卦有先難之困，若能舒之則利。',
  },
  同人: {
    主要: '表有志一同，相和而成。主吉象。理念相同共處愉快。事業投資、感情皆是和睦融洽。最適合找人合夥事業或尋求認同自己理念的吉卦。',
    特性: '喜團體生活戶外活動，有領導才能，善交際，人際關係佳。有幽默感。',
    解釋: '結合志同道合的人集思廣益。',
    運勢: '得此卦為諸事開通、平安、吉祥、如意之象，有同情心，互愛、亨通之象，故宜好好掌握時機，維持盛泰。若有稍涉偏私或為私情之愛則有破兆，應以事業為重。',
    愛情: '互悅其情，彼此間謙和以禮互敬，魚之得水，定成美眷。切忌氣盛，宜和。',
    家運: '上下和樂，運勢亨通，可得意外之財，亦可促進開展事業之樞機，宜把握良機，努力求取。',
    胎孕: '無礙。與六親和睦。得緣之吉卦。',
    子女: '上下皆能和睦之象。',
    週轉: '順利，並可進取大業，利也。',
    買賣: '利益大，與人共事吉，宜木字口字姓之人同相利濟更吉。',
    疾病: '雖有病，但需是動爻而論。為腦部、神經衰弱或呼吸之疾病，心神恍惚、失眠、心火氣旺之象，宜多調養、修持參佛。',
    等人: '一定會來，且會帶來好消息。',
    尋人: '此人非故意出走，自己會回來。',
    失物: '很快可以找到。',
    旅行: '利，放心。',
    訟詞糾紛: '與人爭端無益，他侵我或我傷他皆不利。和解了事為上策。',
    求事求職: '吉利。',
    考試: '成績優良。',
    改行: '改行、開業者均吉利亨通。',
  },
  大有: {
    主要: '表有很多的收穫。是個吉卦。易經解釋大有卦為，春耕、夏耘、秋收、冬藏，是個努力堅持之後的願望期待實現。很利於投資、合夥、感情、婚姻。',
    特性: '具有組織能力，能擴展大事業，吸收各方專才，克服困難，有努力完成大目標勇氣，榮譽心重。',
    解釋: '旺盛大有收穫。',
    運勢: '得時得運，隆盛昌榮也。然卦象藏有盛極必衰的預兆，不得不慎，以期保持其全盛之樞紐，可眾望所歸。有走紅、賺錢之勢，須謹慎不可驕縱。',
    愛情: '可實現理想之吉象。',
    家運: '生平富貴吉利。切勿因富而凌貧，過於傲氣凌人者不利也。',
    胎孕: '生貴子女，宜往大醫院。古人云',
    子女: '兒女有成，父母歡欣之象。',
    週轉: '大有所得。',
    買賣: '交易有獲大利之象，但亦宜審慎行事。',
    疾病: '為高血壓、肺部等疾，治療後可無礙。然其病須注意，反態則凶。',
    等人: '會帶來好消息。',
    尋人: '此人為感情或金錢之事出走，宜速找尋，否則恐有走極端之險。以東南再南方再西北三向尋之。',
    失物: '於南方或西北方之高處尋之，可尋。',
    旅行: '可。',
    訟詞糾紛:
      '大事可成，小事有頭無尾，木字人是貴人，異性之人有驚，可得圓滿解決。',
    求事求職: '順利可行。',
    考試: '科甲明登。',
    改行: '可以依計劃進行。開業吉利之時。',
  },
  謙: {
    主要: '表謙虛不出風頭之意。主吉中帶小凶。謙卦顯示除了謙虛不與人爭之外，還有一個很重要的意義，就是被「壓抑」！在目前到處都充滿競爭的世代裏，彼此競爭、排斥，是很正常的一種現象，所以，謙卦就是告訴你，雖然一時被壓制了，但是忍辱負重，很快就會再意氣風發的。因此不要氣餒、灰心。另外，也顯示，事事不可強出頭，目前局勢下，謙讓不意氣用事，才可得吉象。',
    特性: '待人謙恭，隨和，交遊廣泛，喜休閒活動，重視生活安排及財物的規劃。並不斷追求新知，配合時代脈動。',
    解釋: '要謙虛才可得到助力。',
    運勢: '吉利平安，欣欣向榮之象，謙者前途大利，驕者橫行招敗。故以君子謙德，縱涉大川而無險也，卦利君子。正所謂謙受益滿招損也。',
    愛情: '以謙恭相追求有成，可獲良緣，反之失利。',
    家運: '幸福之吉運。',
    胎孕: '生男兒無災。生女兒亦無災。',
    子女: '子女多溫順，事親孝，可賀也。',
    週轉: '誠實謙恭，可成也。',
    買賣: '終成，有是非。獲薄利，益也。',
    疾病: '為腹部與頭部之疾，或有舊病復發。可癒。',
    等人: '準時到達。',
    尋人: '未見，待一段時間自回。',
    失物: '東北或西南失之可尋，東南失之難求。',
    旅行: '可。',
    訟詞糾紛: '有官事，拖延不利，宜速和解。',
    求事求職: '吉利。可成。',
    考試: '金榜題名。',
    改行: '改行可行。開業者吉利，但諸事勿太急，以謙則受益。',
  },
  豫: {
    主要: '表高興悅樂之意。主小吉象。此卦顯示做事積極、有力，性情柔和、圓融，充滿令人喜樂之氣象。凡事可盡力去做，無大礙。婚姻、感情，男建、女歡，可得良緣。',
    特性: '穩重，有組織領導的能力，心地善良樂於助人，做事積極務實有效率。精於計畫，有金錢觀念。',
    解釋: '順應天命。',
    運勢: '此卦象徵萬物欣暢，如意安泰，可得上輩之助。切不能因繁華而怠惰，或沉醉於聲色歡場之中。好運不常有，當好好把握。',
    愛情: '一帆風順，天賜良緣。',
    家運: '吉象。切不可迷於情而招破相。',
    胎孕: '孕安。',
    子女: '兒女均能相親相愛。前程美好之數。',
    週轉: '稍有阻，但可順利。須誠。',
    買賣: '好時機。秋更吉，有貴人。',
    疾病: '肝臟、腹內之疾。',
    等人: '途中有礙，一定會來。',
    尋人: '東方或西南方可尋。或他人自來相尋。',
    失物: '速尋，否則難得。',
    旅行: '可以。',
    訟詞糾紛: '有頭無尾，有震動驚恐，亦有別人連累，破財。',
    求事求職: '可得上輩提拔，有希望。',
    考試: '金榜題名。',
    改行: '改行吉，大有可為。開業者大吉大利之象。',
  },
  隨: {
    主要: '表隨遇而安，一切隨緣、隨和。主吉象。事事均可依隨著自己的心思計畫來執行，而會順利地達成。事業、投資、理財、感情，均會有令人滿意的發展和收穫。',
    特性: '交際佳，貴人運多，善謀略，為輔佐良才，保守，被動，蕭規曹隨。',
    解釋: '跟隨上司主意行事。',
    運勢: '物事均有去舊迎新之吉象，凡事與他人互相通達、協商，可名利雙收。倘若三心二意，或獨立單行，不聽人言勸，有自招災禍之虞。',
    愛情: '目前重於情慾、經濟，依賴心較重，順著時事、心性而行即可，感情如家人般的結合。',
    家運: '本身有相當不錯的收入，富有之象。但不可過於放縱，守操節為宜。',
    胎孕: '無礙。無驚險。',
    子女: '親情融洽，與六親和睦，幸福之象。',
    週轉: '和氣相商有利，意氣用事則難成。',
    買賣: '勿頑強固執，或不採納別人的意見，則交易有利。否則失之，有阻。',
    疾病: '占病凶，須長期治療。亦須注意口部、呼吸系統肺部及肝臟之毛病。吉凶需以動爻、變卦來判斷。',
    等人: '會遲到。',
    尋人: '不必去找了，過些時日會自己回來，勿急。',
    失物: '附近之東方或西方，混雜於某些物品中，可尋。或自己誤失，可急尋。',
    旅行: '吉利。有人隨行更好。但此卦亦須防單獨外出，小心無謂之災。',
    訟詞糾紛: '日前無憂，不久有，互有意見。和解為宜。',
    求事求職: '雖吉，然若能謙遜待人，多接受別人意見，會得人提拔。',
    考試: '成績理想。',
    改行: '改行有充份計劃者可行。開業吉利。',
  },
  蠱: {
    主要: '表受到蠱惑、毒害。主大凶象。蠱毒是因內在腐朽而生蟲化毒，顯示問題的根因存在已久，卻未誠實面對處理而導致愈來愈嚴重。若是動爻在外，則表示被外來的小人所害，其怨恨根結甚深。易做出錯誤的決策，慨大都被人所迷惑、而一時心神錯亂引致禍事。',
    解釋: '中蠱毒，無可救藥。',
    運勢: '諸事不如意、積弊已深，未能進展，氣運雜亂。逢此宜鼓起勇氣，大膽加以革新，有內憂外患之象。',
    愛情: '關係複雜，尤其男人到處留情，苦惱多，終會一刀兩斷。另覓對象可吉。女子若是再婚者，其前夫不良，且已斷緣再嫁。',
    家運: '家庭易生煩心之事，不徹底改革有破家、損財之象。',
    子女: '雙親不堪其憂之苦象耳。',
    週轉: '信用不好，週轉無望。',
    買賣: '交易不成，另謀生計為要。',
    疾病: '為內臟惡性疾病。冬季占得險象也。',
    等人: '中途有變，不會來。',
    尋人: '因家庭感情失和而出走，或與人私奔，於東南或東北。',
    失物: '難尋。',
    旅行: '有險。不宜。',
    訟詞糾紛: '不易解決，會拖一段時間。',
    求事求職: '不利。',
    考試: '落榜。',
    改行: '開業者不宜，有損財或官符之災。',
  },
  臨: {
    主要: '表大駕光臨，貴人來到之意。主吉象。臨又為「君王臨幸」之意，是上者對下者之象，在事業投資，為貴人相助之意。在感情則有期待而至的歡喜感。只是以上之象皆有，人尊己卑需仰息於人的感覺。',
    特性: '待人熱心，心胸開闊，樂於助人，尊重他人，體會別人心意，謙恭有禮。自然貴人多來幫助，財運豐厚。',
    解釋: '好事即將來臨。',
    運勢: '運勢漸增，諸事亨通如意，前途有望，仕途有成，可得貴人相助。上下安合。以和順可奏大功，宜誠心待人，可諸事順暢，急進或忘形者，有破財之象。',
    愛情: '幸福圓滿，切勿意氣用事，否則有反。',
    家運: '家庭內部不和，有口舌爭端之害，並有散離之象，力持仁與和，運破敗，離散之凶象也。',
    胎孕: '生貴子。求佛及家神則安。產婦慎飲食。',
    子女: '得孝順之兒女。',
    週轉: '可成。',
    買賣: '防口舌，勿過急，和順交易有利。',
    疾病: '為胃病，泌尿生殖系或腹部、口腔之疾，不嚴重。',
    等人: '有好消息且準時到。',
    尋人: '西方或西南方，不必急，目下其人已動。自回。',
    失物: '在灶下或水邊，遲找即空。',
    旅行: '吉利。',
    訟詞糾紛: '三人之事，本欲欺他人，反傷于己。有貴人主和，或莫再爭。',
    求事求職: '順利可成。',
    考試: '金榜題名。',
    改行: '改行吉利。開業正得其時，大吉大利。',
  },
  觀: {
    主要: '表觀察、觀看。是個最標準的狀況卦，吉凶未定。凡事不可輕下決定，需要再觀察一下局勢再做打算。尤其是對長官、上位者的態度更是要察言觀色，不可輕忽大意。',
    特性: '有創意，喜歡開發新事物，見解新穎，愛旅行，觀摩他人，研究比較。不斷修正反省自己。內心易有即墨感。',
    解釋: '尚在觀察中。',
    運勢: '處在有危險不穩的時運，耍多觀望時勢之利弊，待機行事，於人事物，須以誠待之，才能感化而倖免陷入困難也。',
    愛情: '外表看好，內則虛浮，有上輩提攜可望達成，否則尚處在漂動不穩定的狀態。',
    家運: '正處於下坡時段，要知固守進退之機，聽取長輩意見有助也。',
    子女: '兒女將來有成就幸福耳。',
    週轉: '雖有好言，實際內在空虛。無指望居多。',
    買賣: '交易有礙。應在戌亥日可成，否則難成。',
    疾病: '為腹部、神經系統之病痛，病情怪異且有變動，宜妥為治療。更需審慎再加檢查詳細，不可隨意斷症。',
    等人: '觀望必有等待，而來者有隨之他去之象。',
    尋人: '此人到處漂泊，行蹤不定，有險象。目前在西南方，宜丑未日見。',
    失物: '難尋。',
    旅行: '不宜。',
    訟詞糾紛: '雖然終會和合，但有小人在內，防亥日有武動。愈爭論紛爭愈多。',
    求事求職: '不易。',
    考試: '不理想。',
    改行: '擅自主張不宜，若由他人提拔則可。開業者不宜。',
  },
  噬嗑: {
    主要: '表如鯁在喉、難以決策。主吉凶未定，是個狀況卦，有點偏小凶。也如同「雞肋」一般，食之無味、棄之又可惜！是需要堅決下個決心的時候了！',
    特性: '生命力強，堅守奮鬥，百折不撓，要求自己很高，不斷充實自己，突破超越提升。克服困難，宜往大企業大單位任職，較能有所表現。',
    解釋: '被吞沒掉。',
    運勢: '諸事不遂心，多受阻害。有紛爭，無法前進之時，宜常守原則，不為利誘，以免被人中傷。然而一般人多有無法逃避利誘者，故必受禍，若能和氣處事為安。更須突破困難方可通達。',
    愛情: '有被人阻礙或橫刀奪愛之事。但勿氣餒，勇往邁進即可成功，否則放棄罷了。',
    家運: '家庭有不和現象，亦常有無法溝通之阻礙。除非彼此能夠排除成見，否則無法融洽相處。',
    胎孕: '胎有驚。',
    子女: '兒女倔強、反抗。',
    週轉: '卦已明示有困難，但誠心或可圓滿。',
    買賣: '多阻撓且多是非。但若積極推展，不要放棄，則有利，可成。',
    疾病: '可能有神經方面、心臟、足部及腫瘍之症。',
    等人: '受到阻礙，不能來。',
    尋人: '必因發生爭端或涉嫌重大事件而出走。去廟觀市井求之，東、南方。',
    失物: '失此物而有二人爭論之數。可尋束、南方，或夾在某物品中，一時不易察覺。',
    旅行: '不宜。',
    訟詞糾紛: '非必要時，宜採取強硬措施。訴之則可得解決。',
    求事求職: '多生枝節，不利。唯有積極去謀求方有所成就。',
    考試: '不佳。',
    改行: '改行有礙，但耐心排除可成。開業吉，中途有是非，宜耐心解決。',
  },
  賁: {
    主要: '表美麗得外表裝飾。主小凶象。「金玉其外、敗絮其中」最佳解釋。經過有心修飾、偽裝的外表或事情，事業投資、感情皆不宜。慎防有心的小人、偽君子。',
    特性: '重精神，輕物質，有藝術文學專才，感情敏銳，追求理想。在情愛上，易留下刻骨的回憶。',
    解釋: '表面好看、裡表不一。',
    運勢: '卦象是象徵其人外表好看，內在空虛，因此，必須充實自己，凡事深思遠慮，與人和睦相處，能獲得意外利益。切忌因小失大，更無須為了掩飾外觀而造成不必要的損失。凡事踏實，按部就班為是。',
    愛情: '不可自視太高，也勿把對方及自己都估計太過，須知華麗的外表並不能決勝負，宜相誠以對，若自視太高反不得所愛。宜慎。',
    家運: '外美內虛而不為外人所知，應即時整頓家庭經濟，使其安穩、充實，更須安份自持，莫以虛浮來掩飾空虛。踏實為上策。',
    胎孕: '孕有不安之象。',
    子女: '子女身體虛弱，美麗而得人緣。',
    週轉: '不宜大，小調尚可。',
    買賣: '有貴人，速決有利。',
    疾病: '為假象之病症。概與心病、心理、情緒有關係。',
    等人: '會來。但遠方者不來。',
    尋人: '已在東北或南方親友家，可尋。',
    失物: '東北或南方，可尋。',
    旅行: '可。宜近不宜遠。',
    訟詞糾紛: '有貴人和解，宜速解決，拖延不利。',
    求事求職: '條件勿太苛求，可成。',
    考試: '不理想。但人家以為你讀得不錯。',
    改行: '改行宜，但勿誇大或太過。開業者吉利之象。',
  },
  剝: {
    主要: '表剝落之意。主大凶象。地動山崩，一時群山崩落為平地，表示很嚴重的動盪、變化，而致使重大的傷害、不幸的產生。最易有急症、意外的血光之災。事業、感情、婚姻，得此卦需有心理準備，所有之前的努力可能要重新再來了。唯此卦利於重新到外地發展，或另尋對象也可。',
    特性: '反應靈敏，思想新穎，走在時代尖端，有研發長才，一生多貴人運。有意料之外的機會和柳暗花明之遭遇。',
    解釋: '剝削，剝落。',
    運勢: '運帶破、帶衰，有不利之災禍，不可抱持野心，自作聰明，否則自掘墳坑。得此卦者，必有與歡場女子耗費餞財而又被陷害之苦惱，且有受部下連累而損財，為不得志之時運也。',
    愛情: '卦象陰太盛，故男求女利，女求男不利。口舌多，不可奢望太高。',
    家運: '正處於沒落之時，辛苦勞心，有心挽回者可得安，否則有別離之險象。',
    胎孕: '虛驚之凶象。',
    子女: '緣薄，不和，體弱。',
    週轉: '無望。',
    買賣: '難成。若成亦有損。',
    疾病: '為胃膽之病。男人防性病。',
    等人: '不會來。',
    尋人: '在東北方的山上或水邊尋之。',
    失物: '已失於水中。',
    旅行: '不宜。有險象。',
    訟詞糾紛: '因財祿爭鬥致訟。',
    求事求職: '順而止，要慎機行事，不可粗心大意。',
    考試: '落榜。',
    改行: '改行不利，必損財。開業者有阻礙，切勿意氣用事，否則有害。宜再待時。',
  },
  復: {
    主要: '表重複再來、週而復始之意。主是個狀況卦，吉凶未定。好事會重複，但壞事也會重複再來的。此卦顯現一種循環的狀態，可能是好、也可能是壞。',
    特性: '為最佳輔佐人才，做事謹慎，調理分明，精企畫，組織，一生財運豐足，樂於助人，排解別人困難。人緣佳。',
    解釋: '一年復始、萬象更新。',
    運勢: '一陽來復，萬事甦伸，不久就會好轉。初時不宜急進，腳踏實地則有一本萬利、開運亨通之象也。',
    愛情: '性急則敗，緩可得利。',
    家運: '昌隆茂盛，漸曙光明之象。',
    胎孕: '主產母有驚懼之災。',
    子女: '辛勞之後必得享福，兒女將來出人頭地也。',
    週轉: '難求，反覆終可入手。',
    買賣: '有是非，故勿太急，可有利。',
    疾病: '為慢性病、會反覆發作，病症主在腸胃或神經系統之症，注意肝功能。',
    等人: '會遲到。',
    尋人: '自身反覆終人不動，但七日內可回。',
    旅行: '有利。',
    訟詞糾紛: '無罪，但須數次方可了解。',
    求事求職: '勿太急，可成。',
    考試: '愈考愈好。',
    改行: '改行鼓勵。開業者鼓勵，但宜沉著，勿急躁。',
  },
  無妄: {
    主要: '表不要有一些空幻的想法，或是你的想法可能有點虛妄不切實際。主小凶。建議凡事要務實、踏實一點。也表示目前所處的狀況，是有一廂情願的心態。或是有點「妄想症」的不正常心理。',
    特性: '言詞犀利，易生是非，憤世嫉俗，不流俗，求知慾強，重精神，曲高和寡。',
    解釋: '無妄之災。',
    運勢: '無妄的道理者，乃真實無虛要踏實。故若做事操守而能堅貞固執者吉。若不行正道，行為不檢點者，必然有災禍至也。切忌醉沉於利、慾之中，慎之。',
    愛情: '成功與否，須看各人之修為德性而定。然此卦有不順遂、不和，或有被傷害者欲分離之象耳。',
    家運: '有不和之情況，因另一方各受外界誘惑而心亂，須謹慎，否則導致破運。',
    胎孕: '或有驚終無礙。其性剛強，切勿過度溺愛，否則不受教也。',
    子女: '注意與其父不和而離家出走。',
    週轉: '誠心有望，不誠免談。',
    買賣: '若不要過份苛求，有利。',
    疾病: '雖有虛驚，盡力療養可脫險境。為呼吸器官及神經性之疾病，若意氣用事而不仔細調理則凶也。',
    等人: '一定會來。但有不歡而散之象，宜以和為貴。',
    尋人: '已定遠，不易尋找，東北或西北之向。',
    失物: '海底撈針。',
    旅行: '為公事出差則吉。若私人遠行，但有所交易者，則勿遠行為宜。',
    訟詞糾紛: '有貴人和事而脫散無虞也。',
    求事求職: '毫無頭緒，勿過於草率為宜。',
    考試: '筆試有利，口試不利。',
    改行: '改行者不宜勉強，開業者可行。',
  },
  大畜: {
    主要: '表會有很多的積蓄、不動產的來到。吉相之卦。可以考慮較大的投資案，只是現金周轉可能會較不利。蓄也有守財小氣之象。',
    特性: '反應快，追求新知，不斷創新，研究新事物，待人謙虛，修養好。貴人多助，容易少年早答，年輕就在社會露頭角。',
    解釋: '大有積蓄。',
    運勢: '大凡諸事不可好高鶩遠，腳踏實地、務實行事，始能成就大業。以蓄德為主可吉，若因得勢而氣盛凌人，目空一切者，終招失敗之象。',
    愛情: '雖有些阻礙，但終有望，然氣盛則自招叛而破。',
    家運: '須知蓄者以備急需也。明此道理，持之力行，則可亨通。',
    胎孕: '占得此卦主生貴子。',
    子女: '兒女必聲揚家風，可得幸福之象。',
    週轉: '可成。',
    買賣: '再談可成交，且有獲大利之象也。',
    疾病: '為腦、腹部脾、膽之疾，可能結石發炎之象。或頭部外傷。但可冶。',
    等人: '不會來。',
    尋人: '途中虛險，若一人等待必有凶。若是出走者，不必尋，會自回。',
    失物: '隔些時日可尋，東北水邊。',
    旅行: '吉利。',
    訟詞糾紛: '因土地之事，有頭無尾。有破財之象，但糾紛可化解。',
    求事求職: '不利。再待時。',
    考試: '上榜。',
    改行: '改行不宜。開業者要有不怕挫折之決心全力以赴，有利可得。',
  },
  頤: {
    主要: '表養也。主小吉帶一點凶。養跟飲食有關，也表示養育、教育的意思，對情勢需要再充實、瞭解，不要貿然的就下任何的決定。頤，又與口有關，故需慎防小人口舌之災。',
    特性: '先苦候樂，失敗後成功型，物質金錢運佳，樂善好施，貴人多助。不斷提升修養自己，容易得到社會好的名聲。',
    解釋: '靜臥，不向前衝。',
    運勢: '有欠缺考慮、魯莽妄動之害。若有懷陰謀，與人互相猜疑或爭端者更不利。要守正道而善行之，凡事切忌任性非為。',
    愛情: '不可傲氣凌人。雖有些阻礙，但此情令人意亂情迷，耍冷靜觀察。因為頤卦為養，即是食，對自己本身之損益，跟自己吃下去的東西，是相對性質的反應。故宜重新估計對方的價值，是益是損？切勿因情誤了自己的青春。',
    家運: '陷於痛苦深淵，有身敗名裂之象，宜改進言行，冷靜反省，力求幸福才是。',
    胎孕: '宜求香火保佑。',
    子女: '過份溺愛反有害。尤其須注意兒女健康，以免日後因身體虛弱而不能獲福。',
    週轉: '是否成功，要看自己有否誠心。',
    買賣: '守舊為宜，不宜新事所為，否則不利。',
    疾病: '為胃腸、脾膽發炎。占久病凶兆，久病者險。',
    等人: '難來，中途有礙。',
    尋人: '不久相逢。東北或東之向。',
    失物: '在屋內雜物中尋。',
    旅行: '可。但注意飲食與語言得失。',
    訟詞糾紛: '有頭無尾，我告他人終久不成，若有些利，速予和解。',
    求事求職: '不成。',
    考試: '尚可。',
    改行: '改行不宜，勿勉強。開業者不利，再待時。',
  },
  大過: {
    主要: '表犯了一個很大的過錯哦！主大凶。諸事不順，切忌此時作任何的決策。需努力找出問題之所在，儘快解決才能扭轉劣勢。',
    特性: '心直口快，性急，易得罪他人，是非小人多，但交際層面廣，朋友多，但也易受朋友之拖累。',
    解釋: '極大過失。',
    運勢: '諸事衰退，多煩惱，恐水難或犯官符。有力不從心，負擔過重之象。逢挫折而將墜落之時運，故得此卦者，凡事切忌經舉妄動，否則有過。',
    愛情: '雙方眼光均太高，且各已有相好之人，花心又不實，何需再另談感情之事？無望。',
    家運: '困難重重，搖搖欲墜之衰運。夫妻感情不睦，且均有向外發展之趨勢，若想挽回此破象，雙方宜力圖改進自己的缺點，否則破裂也。',
    胎孕: '女兒或男兒均與六親緣薄，育養困難。',
    子女: '子女與雙親間感情薄弱，又缺乏諒解，有癥結耳。',
    週轉: '無啥希望。',
    買賣: '不可做超過自己本身財力之生意交易，否則定有折損。亦不成。',
    疾病: '有更嚴重之趨向，為肺部、神經部份的疾病。女占得此卦，有婦女經滯、婦女病、不孕、腫瘤之疾，須耐心治療，疾可治。',
    等人: '不會來。',
    尋人: '不易找到，可向東南方或西方。難尋。但若動用人手共尋找，可見。',
    失物: '小失可追回，大失無望。若是東南向或西向則較有望。',
    旅行: '不去也罷，不利。',
    訟詞糾紛:
      '乃熟人、親近之人之內患。有文書上的麻煩，求和亦尋無心計。官符也。',
    求事求職: '困難很多。',
    考試: '苦惱也。不佳。',
    改行: '改行不宜圖不相應之事。開業尚屬虛，且待時候。',
  },
  坎: {
    主要: '表陷溺被水淹滅之意。主凶象。四大難卦第二卦。困難已到來了，此時正在掙扎中，隨時會被淹滅而破散。若能破釜沈舟、奮力一擊，或許尚有轉機，需積極找求貴人，才會有生機。',
    特性: '不安定感，好冒險，投機，變換工作環境，愛情多困擾不順。宜從事旅遊服務社交藝術創作業。',
    解釋: '漂浮，被情所困。',
    運勢: '危機重重，宜容忍自重，保持心境開朗，沉著應付，則可有脫險之機。吃得苦中苦，方為人上人，凡事莫與人爭長短，否則有不幸災害。',
    愛情: '溺於情愛之中，然本身和外圍的困擾和壓力都很大。',
    家運: '時運不濟，陷於困境之中，不和，且有分裂之憂，凡事宜慎重行事。',
    胎孕: '臨產有虛驚，難產之象。',
    子女: '子女多勞苦，宜忍辱負重，會有出人頭地的一大。子女宜防水厄之災。',
    週轉: '難成。',
    買賣: '虧損。',
    疾病: '腎臟、生殖器官等部位之疾。久病者凶，近病者小凶可治，速醫或可救。',
    等人: '不來。',
    尋人: '此人因家庭不和或不得志而出走，速尋北方水邊。',
    失物: '難尋。或被盜。',
    旅行: '不宜；尤忌游泳、近水邊，有被水淹滅之勢。',
    訟詞糾紛: '有理亦不可因有理仗勢。此卦不宜訟訴，有險。大不利之象。',
    求事求職: '不利。',
    考試: '不理想。',
    改行: '改行不宜，應暫時守住本行。開業者不利，有陷入困難之象。',
  },
  離: {
    主要: '表智慧、明亮、溫暖。六沖卦、純卦，主大吉大凶、大好大壞之象。也表，虛象不實之意。',
    特性: '愛現，喜歡與人競爭，衝動，自尊心強，不服輸，容易遭人排斥反對。依賴自己努力，他人助力較少。',
    解釋: '變動突發火光。',
    運勢: '雖然目前外觀極盛一切順利，可能為假象、虛象，而且雙火之燃，其內部、內情已有所損，又雙火之烈，如烈日中天，故凡事宜守之，勿太急進，待人以謙和、理性，否則必有所損失。宜順從長輩，勿因急躁而妄動或意氣用事，則可欣欣向榮也。',
    愛情: '對方明亮有活力，性急者弄巧成拙，誠靜者其情必有成就。',
    家運: '富有幸福之運，必須保持仁和謙恭，驕傲者，自大招致失敗，宜謙遜處世。',
    胎孕: '胎有不安。產母不宜出外遊。',
    子女: '子女幸福，但有嬌生慣養之嫌，勿疏於教導為上',
    週轉: '可得到上輩的幫助渡過難關。',
    買賣: '交易必得利，忌用不法手段。',
    疾病: '心臟及眼症，雖重，耐心調養可無礙。虛火很旺，情緒浮動，需特別注意血光意外之災。卜急病有解，慢性久病卜得此卦則凶。',
    等人: '一定會來。',
    尋人: '此人因受到誘惑或煽動而出走。南方可尋，且小有是非。',
    失物: '於南方，附在某物件上。急尋可得。',
    旅行: '可。但勿太急。',
    訟詞糾紛: '內動則外凶。他人理虧，自取其危，我侵他人卻又成凶。',
    求事求職: '尊重長輩安排。待時機可獲良好事業。',
    考試: '成績不錯。',
    改行: '改行可極力推展。開業者吉象。',
  },
  咸: {
    主要: '表感動也。主吉象。感，有如青春男女，清純無暇的感情，心心相印有感而發的情感，得此卦，與感情有非常大的關係。也可引伸為對一種理念的認同和欣賞。咸，並無利慾的情色糾葛，是屬於比較「感性的」一種感覺。對男女之間的感情，是最大的吉象。',
    特性: '羅曼蒂克型，異性緣佳，感情豐富，六親緣濃，口才佳，貴人多助。',
    解釋: '合和。',
    運勢: '吉祥如意。但勿為不正當的感情而意亂迷惑，或過於衝動。然得此卦時，會有男貪女愛之箏，非名正者必遭損，宜慎之。',
    愛情: '互敬互愛，清清白白者定成眷偶。若名不順而貪愛者，當知懸崖勒馬。',
    家運: '目前非常融洽，爾後亦然。如夫婦間另有不正當之交往者，宜明哲保家，避免有越軌情事為宜。',
    胎孕: '有興家和泰之運。',
    子女: '感情極融治，幸福。',
    週轉: '可成。',
    買賣: '如期望，交易可成有利。',
    疾病: '泌尿系統之症或胸部呼吸系統之症。須療養得法。',
    等人: '會帶來好消息。',
    尋人: '因色情之事出走，不久會出現。其人有在住處動身不得之憂，恐有些是非。',
    失物: '東北或西南方內尋找，可得。',
    旅行: '吉利。',
    訟詞糾紛: '恐為美色糾紛。諸事是非宜和解，有貴人可解之。',
    求事求職: '皆能如意得償。',
    考試: '成績佳。',
    改行: '改行吉利，可進行。開業大吉大利。',
  },
  恆: {
    主要: '表維持不變的跡象。主是個狀況卦，吉凶未定。顯示一種會持續很長久的一種狀況。好的或壞的情形，都將會再持續下去。事業投資、感情婚姻都不宜在此時做任何的計畫或變動。',
    特性: '主動，積極，堅忍奮鬥，百折不撓，律己嚴，反應快，追求成功，理財佳，組織能力好。',
    解釋: '要有恆心。',
    運勢: '諸事亨通而沒有犯錯方能恆久。其象利於正常固守，並須有毅力持行。雷風者，知守常則吉，妄動者則不能亨通。',
    愛情: '順利如意，有美好結果。',
    家運: '全家上下均各循正軌而行，幸福家庭也。',
    胎孕: '胎有煩惱，但無礙。',
    子女: '與雙親感情融治，幸福之至。',
    週轉: '不成問題。',
    買賣: '有貴人，得力終成。利益雙收。',
    疾病: '慢性病及宿疾。膽、肝之症，須長期治療不易在短期內治癒。',
    等人: '遲到。',
    尋人: '外出之人平安無事，會自己回來。',
    失物: '室內可尋東南方，室外宜向西北方。可尋。',
    旅行: '吉利。',
    訟詞糾紛: '因小人口舌而起，有驚無害。宜速謀和解，拖延不利，反凶。',
    求事求職: '向東南有利。',
    考試: '高分紀錄。',
    改行: '改行不可任意圖謀。開業者吉利可行。',
  },
  遯: {
    主要: '表退守、退步之意。主凶象。一切事項均需停止下來，因其中可能有小人、或障礙、陰謀在破壞中。退另有表示退步之意，功課、事業，均不再如同往常般的成績了！',
    特性: '外華內虛，內心不易滿足空虛，自我要求高，不善人際關係，但有貴人運。宜追求心靈宗教寄託。',
    解釋: '隱居退守。',
    運勢: '小人道長，君子須遠離小人，諸事宜守。艮者，止也，言行物事要謹慎，才能得安而運泰。若遇小人之害，見陷於是非之地，勿管閒事，識時務為俊傑。',
    愛情: '落花有意，流水無情，適時地另外選擇其他對象吧！',
    家運: '須多積德行善，可得安祥。若常爭端則衰運，且有別離之象、離婚之象。若曾數經波折之君子，歷盡滄桑，則有先惡後吉之運。',
    胎孕: '母子不安。慎之。',
    子女: '兒女體質虛弱，有親子不和之象。',
    週轉: '不成，有礙。',
    買賣: '宜守，不能大事進取，否則失敗。',
    疾病: '所患者為經絡血行不良，袖經系統之麻痺、疼痛，或手骨、頭疼之症，常病也。',
    等人: '遁，藏也。不能來。',
    尋人: '下落不明，為家庭之事而離鄉背井，尋亦難覓。',
    失物: '已落人他人手中，難尋得。',
    旅行: '不利。莫去為安。尤其山路有險。',
    訟詞糾紛: '有一方因逃移而去，是非真假無須論斷，爭之不利，宜罷手。',
    求事求職: '須待時機。',
    考試: '不理想。',
    改行: '改行不得時，開業不利。',
  },
  大壯: {
    主要: '表天上打雷、聲勢浩大，行動迅速、積極之象。此卦一般以吉處藏凶來論。因為對人、對事最忌衝動。此卦有過於不理性衝動之象，容易犯錯。尤其有血光之禍，被人毆打、傷害之意。得此卦，凡事再三思為吉。',
    特性: '愛冒險，領導慾強，喜怒易形於色，不喜歡太平淡安定的工作生活。金錢支出大方，朋友人緣不錯。',
    解釋: '聲勢浩蕩。',
    運勢: '剛強過盛者，若不思正與順，則有躁動之害耳。雖運勢強大，時至當盛之期，但忌血氣方剛。處事欠思慮而遭破運，宜力持和平、順氣，勿得罪人，否則會因此而招來失敗，切勿貪妄。',
    愛情: '有成就，但缺乏仁和謙恭，易遭反悔，須慎之。',
    家運: '已壯大且富有，但亦有似成而空之感。',
    胎孕: '子母有難非喜。',
    子女: '兒女有自視太高，目中無人之情。',
    週轉: '勿再三借貸，如此反而不利。',
    買賣: '可成功與獲大利，但往後要小心，有反遭不利之情況。',
    疾病: '所患為急性肺炎、腦病等。尤其平常健壯而臨時起病者不利。',
    等人: '遲來。',
    尋人: '為鬥氣而出走，若尋找途中不見，則難查下落，東方或西北方。',
    失物: '大多已失。',
    旅行: '有無謂災害，不宜。',
    訟詞糾紛: '和解為宜。勿鬥氣。',
    求事求職: '可順利。勿太剛強行事。',
    考試: '有好成績，但勿太驕傲。',
    改行: '改行可，不過勿太勉強。開業吉利。',
  },
  晉: {
    主要: '表進取也。主吉象。火為太陽，所以是「日出於大地、光明乍現」，陽光準備要照耀大地，一片欣欣向榮之象。事業投資、升遷、婚姻、感情均是有雨過天晴，奮發向上的吉象。',
    特性: '榮譽心重，擇善固執，愛面子，外華內虛，喜領導他人，宜往社交，服務業，代議士方面發揮。',
    解釋: '很明白，很明亮。',
    運勢: '事業繁榮，聲譽漸高，於人、事、物均獲宏利之際，與人共事者吉。但要知進德虛懷，即是諸凡對人、事宜謹守德操，不可傲溢心懷，否則有破象也。但財運旺。',
    愛情: '雖如日中天，切不可過於騙縱、蠻橫，若為人不行正道，雖然光明就在眼前，且興隆幸福，又難免夫婦失和，家運不振耳。',
    家運: '家庭內部不和，有口舌爭端之害，並有散離之象，力持仁與和，運破敗，離散之凶象也。',
    胎孕: '平。',
    子女: '多才智，聰明而賢孝。',
    週轉: '可獲得強大資金。',
    買賣: '進取得利，並獲得巨財。',
    疾病: '為胃腸之疾，長病者凶，近病者無礙。',
    等人: '女者會來，男者不一定會來。',
    尋人: '見於西南方或南方。',
    失物: '容易找到。',
    旅行: '吉利。',
    訟詞糾紛: '遲疑終得理，能圓滿解決。',
    求事求職: '受人提拔，可有成就。',
    考試: '科甲登榜。',
  },
  明夷: {
    主要: '表火入坑中，陽氣被陰氣所傷害，被小人所重傷。主大凶象。諸事不宜，運最背之時。',
    特性: '朋友多，心慈，重友誼，喜追求高層次的境界，理智和感情常矛盾。酉時會犧牲一切，去完成自己的理想。',
    解釋: '渾沌不明。',
    運勢: '逆勢下降，時運未濟，物事勞苦，逢小人加害，光明受到掩蔽而失光明，所以多艱難之運也。有萬事阻滯，遇事迷惑、受災。故宜守貞固之道，忍耐自重，等待時機。',
    愛情: '有被背叛拋棄之象，未能被對方接受，不成也，而且受傷很深重。',
    家運: '衰弱、多勞，家人受累，恐有詐欺、官符。須知邪惡之人定有果報，凡事心地坦蕩可渡難關也。',
    胎孕: '產母有驚。',
    子女: '受子女牽連，陷於痛苦深淵之中。',
    週轉: '不成。',
    買賣: '難成，用謀方就。',
    疾病: '為難治之病難。為腹部之疾，或心臟。慢性病卜之，病有不安、嚴重、凶險之象。',
    等人: '不來。',
    尋人: '西南或南方，雖在附近但難尋。',
    失物: '在身上或衣服可尋得。若不在身上衣服裡，則是被某物掩蓋難尋。',
    旅行: '不宜。',
    訟詞糾紛: '見官必難逃走，其他糾紛爭吵有失，和解為宜。',
    求事求職: '日下無希望，須再待時，需防被騙、失財、失身。',
    考試: '無望。',
    改行: '改行不利。開業不宜，亦不成事。',
  },
  家人: {
    主要: '表同為一家人。主小吉之象。事事以家人為重之意，如事業投資均需一家人合作共事為佳。感情，為成家之吉象。',
    特性: '為人熱忱，待人謙恭有禮貌，善交際，外表積極內心保守。喜家庭生活，愛小孩小動物。',
    解釋: '家人離散，吉中帶兇。',
    運勢: '平安無事，且有喜事之象，與家人共事者大利。',
    愛情: '有情人終成眷屬。',
    家運: '物事暢達如意，和樂之象。',
    胎孕: '不礙。',
    子女: '兒女孝順。',
    週轉: '難調，但可成，宜找自家親人為佳。謹守誠信可無阻。',
    買賣: '有超值的利益可得。交易可成。',
    疾病: '腹部小病不嚴重。若為心臟病則危重。',
    等人: '自來。且有吉事相告。',
    尋人: '因賭氣出走，不久便見。',
    失物: '南方或東南，有失，未得見。遺失於室內易得。',
    旅行: '大利。',
    訟詞糾紛: '得理，雖有憂疑，但無妨。能有合理的處理方法。',
    求事求職: '有良好機會，勿失。',
    考試: '上榜有名。',
    改行: '改行勿急。開業者可照計劃而行。吉利也。',
  },
  睽: {
    主要: '表乖危、叛逆、背離之象。主凶象。意見不合，彼此爭鬥、任性、不協調。事事均不順，且有被出賣的危險，注意犯小人。',
    特性: '經常事與願違，個性敏銳，理想化，感情與理智容易矛盾衝突，易生孤獨感，宜往專技藝術發展。',
    解釋: '防小人。',
    運勢: '水火不相容，則氣運不通，勢行低落，諸事難成。凡事有違叛之時，若能處變不驚，不逞強出頭，尚可轉危為安。有家內不和，親友疏散之情。宜力持溫和，忍耐去克服困難，始可渡危。',
    愛情: '彼此間無意向，無望也。',
    家運: '困苦離親之象，不和睦之情。家運衰頹，雖可得小利，但也濟不敷出。',
    胎孕: '無礙。',
    子女: '子女不和，有骨肉無情之不幸，且其子女六親緣薄無靠。',
    週轉: '難以如願。',
    買賣: '多阻礙，難成，若成者亦必損。',
    疾病: '有診斷錯誤，治療上有失先機之危，為心臟、血液、或胸部機能之病症、心臟疾病。病者冷熱不和，重症危，經症速轉醫可治。',
    等人: '不會來。',
    尋人: '因口角負氣出走，其志不同故不易尋。',
    失物: '已被人奪去，追不回了。',
    旅行: '不宜，有災。',
    訟詞糾紛: '宜速謀和解，不然反成無理。',
    求事求職: '無希望。',
    考試: '落榜。',
  },
  蹇: {
    主要: '表寒足之意。主凶象，四大難卦第三卦。冰天雪地中赤足而行，表示現在處境多麼的艱辛困苦，卻又不能放守不管，只能硬撐到底。此時雖是無奈，但也總有苦盡甘來的時刻。人事均很為難之時，事業、感情均有騎虎難下的困境。',
    特性: '幻想多，重情調，喜新厭舊，有設計研發才能，追求理想化的生活，不切實際，故內心易有挫折感。',
    解釋: '跛足，執行困難。',
    運勢: '衰運，多災難，進退兩難之處境，要自重而不可妄動，此時應守正道待時也。凡事雜亂，更防小人之害，絕不可涉險境，否則災害必至。',
    愛情: '困境重重，情緣有破，捨棄難定之際。然此卦主破象。',
    家運: '六親無情，有貧困、不幸之象，逆差者，其險難脫，善者宜致力克服挽救。宜注意家人有重病、血光之厄運。',
    胎孕: '胎不安，孕有災。',
    子女: '子女手足情薄，六親少靠，勞碌之命也。',
    週轉: '難調，多不成也。',
    買賣: '失利。交易不成。',
    疾病: '肝臟、胃、脾膽之症，或有腎、受傷害之疾。',
    等人: '不會來。',
    尋人: '速報警幫尋，得貴人則可回。',
    失物: '可尋東北水邊或溝內。',
    旅行: '不宜。有險象。',
    訟詞糾紛:
      '不宜見官，只宜逃避，因有嚴重的暗鬥而不得停罷，終必有險，帶血光之厄。',
    求事求職: '眼前毫無希望。',
    考試: '落榜。',
    改行: '改行不宜。借貸強行並非良策。開業者不宜。有是非、損耗之象。',
  },
  解: {
    主要: '表解決之象。主凶帶小吉之象。冬雪春化之意。冰凍三尺非一日之寒，事出有因、但已是到該解決的盡頭了！只是化解之日還很長，要抓住重點方向，好好努力堅持下去，還是有可能再成功的。',
    特性: '耐性差，愛變化，喜熱鬧場合，多學不精，思想興趣不穩定，易受外界影響。一生多位他人排解事務，自己卻少有他人之助。',
    解釋: '解凍。',
    運勢: '順勢而行，速把握良機、堅持努力，快速處理可成。貴人在遠方，有助，宜出外營謀。大運在西南。',
    愛情: '不用猶豫，堅持可成。',
    家運: '初有困難，但難關已過。開花結實之兆。',
    胎孕: '孕安。',
    子女: '初時因子女牽累而勞苦，但其子女越長運越好之兆，故苦後得甘，已可得到幸福。',
    週轉: '速進行可成。延遲不利。',
    買賣: '即速交易，可名利雙收。',
    疾病: '為腸胃、神經系統之疾。',
    等人: '一定來。',
    尋人: '北方尋之可見。',
    失物: '遺失不得全出。',
    旅行: '吉利。但莫乘船，其他可以。',
    訟詞糾紛: '可有利解決。',
    求事求職: '有貴人相助。',
    考試: '題榜在望小成。',
    改行: '改行速改有利。開業者有很大的展望。',
  },
  損: {
    主要: '表小有損失也。主凶中帶吉之象。「賽翁失馬、焉之非福！」是此卦最好的解釋。投資、事業、借貸、感情皆是失意不順之時，然對事要有信心，學得經驗將可得到更好的結果。若將要投資則不可。',
    特性: '心地善良，富同情心，悲天憫人，體貼他人，善於照顧，安慰別人。犧牲小我，完成大我精神，生活節儉，大方助人。',
    解釋: '有所損害、損失。',
    運勢: '諸事不如意，若傾於利慾不當之心則有災，散財之損。必須能夠悟其所損，方能挽回局勢。與人共事商量則可收損失之象。',
    愛情: '以誠信可以成功，可得良緣，但此卦女者卜得有較大的損失。',
    家運: '正處於受損之時，審慎挽救頹勢他。',
    胎孕: '產母有凶象。',
    子女: '兒女多誠實孝順，幸福之格。',
    週轉: '可達目的。',
    買賣: '雖有損，但終得利。',
    疾病: '為身心衰弱、脾、膽硬化結石，消化不良，或貧血，治療後無礙。',
    等人: '可能會遲到。',
    尋人: '在東北或西方友人家中，可尋。否則會自回。',
    失物: '北方可尋，別處已失難尋。',
    旅行: '遷居可，旅行不宜，有難。',
    訟詞糾紛: '爭鬥之事，他人先有損，破些財，勿再爭端，宜解。',
    求事求職: '可慢慢受重用。',
    考試: '多努力，榜上可題名。',
    改行: '改行可行，勿急躁，宜週密。開業者吉利，宜有耐力。',
  },
  益: {
    主要: '表利益、好處。是由上對下的一種好的給于。主吉象。對事業投資都有相當的幫助，可得貴人來相助、扶持。',
    特性: '重大我，輕小我，熱心公益，愛熱鬧場合，團體事務協調工作。對自己生活的要求少，在朋友口碑中不錯。',
    解釋: '有利益，有好處。',
    運勢: '乘盛吉之運，可得他人之助而諸事順暢，家業有成。大致吉利之運也。',
    愛情: '相成相益，可成良緣。',
    家運: '有喜悅之象，發達之意。',
    胎孕: '胎有不安。',
    子女: '得家人之喜愛，天真頑皮。',
    週轉: '可成。',
    買賣: '可得大利。',
    疾病: '足或胃部疾病，可癒。有小血光應無礙。',
    等人: '遲來。因有好事相告。',
    尋人: '難逢，防有害。若出走者會自回。',
    失物: '遺失之物有變動，深藏於東南方。',
    旅行: '可行，宜注意小有不測、變動之礙。',
    訟詞糾紛: '雙方可和。若因女人田地糧米之事，恐有牢獄之災。宜自修省。',
    求事求職: '大好時機，勿錯過。',
    考試: '成績優良，父母有喜悅之象。',
    改行: '改行有利。開業者則大有利圖。',
  },
  夬: {
    主要: '表決定或缺失。是個狀況卦，吉凶未定。所遲疑的事情，一定要有個決定不能再拖延，至於如何決定，可視變卦而定。否則在有所拖延，就成凶象了！',
    特性: '思想敏銳，有理想抱負，果決，務實主義，實事求是，不投機冒險，有孤獨感，宜從事專技工作。',
    解釋: '卦相奇特角龍昇天行大運。',
    運勢: '日前雖然鴻運當頭，終會遇到困難與危險，凡事不可輕舉妄動，宜隨時警惕自己，留心意外災害。住所可能會有變動，亦有文書、契約之失利，易生錯誤。慎勿傲氣或自以為是，不謙和者定有災。',
    愛情: '男人不專情之象，乃孽緣也。',
    家運: '夕陽雖好，卻近黃昏，往後有陷於驚動、痛苦之境。因此卦有盛極必反之象。',
    胎孕: '無災。母胎宜節食物，且勿發怒氣，否則有難產之慮。',
    子女: '有先吉後凶之象。',
    週轉: '不可急踩，須誠意，光明之態度可成，否則有不利之象。',
    買賣: '須誠信交易，有失敗破財之害。',
    疾病: '宜速治療。所患是胃部、頭部之病症。',
    等人: '不必等了，不會來。',
    尋人: '宜速尋找，延誤不利。',
    失物: '遺失大致可知，但難得之意，西北方。',
    旅行: '不利，途中有難。',
    訟詞糾紛: '不利與人私下爭執，宜尋法律途徑解決。',
    求事求職: '有困難，或得不到好職位。',
    考試: '不理想。',
    改行: '改行者要慎重考慮。開業者不利，如已備妥 小心難關重重。',
  },
  姤: {
    主要: '表邂逅、不其而遇，非預想中的事情來發生。主是個狀況卦，吉凶未定。逅，會帶來意外之喜、也會帶來意外之災，需視動爻變卦吉凶來論斷。而「姤」意，卜感情通常是有其他的感情發生的跡象、與情色非常有關係。',
    特性: '杞人憂天，虎頭蛇尾，易感傷，有藝術美學之天分，較理想化。',
    解釋: '二女爭一男。',
    運勢: '陰長陽衰，男人傾防因色受禍，諸事不如意，不能上進，沉於女色之凶象也。凡事進取宜慎重。',
    愛情: '男女對象均不理想，有第三者出現，因而徒勞無功，會離散。',
    家運: '家運漸衰，不祥之兆。有女人口舌，亦有感情之因，損財之運。',
    胎孕: '孕無礙。然須注意子女均有品性不良之趨向。',
    子女: '子女多有志行不堅定，給父母親增加麻煩，不幸之象。難管教也。',
    週轉: '有意外阻撓，難調，另謀他法為宜。',
    買賣: '不能如意，受奸詐之害而導致失敗也。',
    疾病: '常為外來感染或外傷、外來沖煞等症。',
    等人: '女方會來，男方不一定會來，因為途中遇到別的女人之故。',
    尋人: '會在意想不到的地方相遇。尋人不易，西北之力。',
    失物: '遺失之物與女人有關，或許無意之中可以找到。',
    旅行: '可。但切記勿近女色，以為艷遇其實有災。',
    訟詞糾紛: '女人在內，口舌是非播弄之象。多數為女色方面之糾紛。',
    求事求職: '難成。',
    考試: '不理想。',
    改行: '改行不利，開業不宜，強行必受小人之害。',
  },
  萃: {
    主要: '表精華聚集之意。主吉中帶小凶之狀況卦。有經過挑選、或是在因緣際會下，而重新再一起共事的意義。事業公司，可能人事有需要再重新安排挑選過。',
    特性: '溫文富同情心，慈悲心，被動，略消極，乏耐性，博學不專。人緣佳，易有情愛困擾。',
    解釋: '人才聚集。',
    運勢: '昌隆，得信於人，承上輩照顧，事業吉昌，但宜小心財務上的糾紛。',
    愛情: '可達成其願望。',
    家運: '家庭融洽如意。防財務歧見之意外。',
    胎孕: '胎安。然產母病多，注意保養身體。',
    子女: '手足相敬如賓。',
    週轉: '可得到上輩支持。',
    買賣: '可獲利。',
    疾病: '所患是胸部與腹部之疾。治療得宜可平安。',
    等人: '一定會來，且有佳音相告。',
    尋人: '會自己回來，不用擔心。',
    失物: '遺失之物在西南方，遲至無用，速則可尋。',
    旅行: '吉利，並有所獲。',
    訟詞糾紛: '爭端不利，和解為宜。',
    求事求職: '可順利，有成就。',
    考試: '常保佳績。',
    改行: '改行吉利。開業適時，可按計劃進行。',
  },
  升: {
    主要: '表一種情勢有如乘風而起，會越來越旺。主是個狀況卦，吉凶未定。有點偏小吉。尤其是在氣運的轉變中，將會顯現得很明顯。此時可以做一些計畫，但還尚不可執行。',
    特性: '有計畫安排自己的人生，做事考慮周詳，待人謙和，追求新的知識。喜歡多彩人生，重生活品味。',
    解釋: '逐漸高升。',
    運勢: '諸事向上發展，開運之象，南方有吉慶，可名利雙收也。',
    愛情: '不宜過急，慢慢追求可成。',
    家運: '漸進而升至繁榮，積小以成大之吉運。',
    胎孕: '不宜修造動土，修造必犯產母。',
    子女: '子女漸有成功之象，可賀。',
    週轉: '勿太過急，可成。',
    買賣: '多有利，應於亥卯日。',
    疾病: '腹部、膽或下部疾病。',
    等人: '遲到。',
    尋人: '動身則見，但恐自身有阻，與他人同去可，宜向北方。會自己回來。東南。',
    失物: '可尋，但遲。',
    旅行: '吉。遠行有利。',
    訟詞糾紛: '宜進不宜退，堅持可成，緩則無大吉也。',
    求事求職: '有利。',
    考試: '進步。進取有利。',
    改行: '改行吉利。開業者吉利，漸漸獲利。',
  },
  困: {
    主要: '表很大的困難被困住了。主大凶象。四大難卦第四卦。四處無援，最困難之時。事事恨難再有進展，只好靜待時機，是此時最好的選擇。',
    特性: '不滿足感，不喜平淡生活，生活過於理想化，愛變化。自立自強，辛勤工作，善於用腦工作，不適領導工作。',
    解釋: '被困住。',
    運勢: '不如意，被小人欺，勞而無功，破損之災。一事難成，運衰也。宜守己待時。',
    愛情: '失敗之象，難成氣候。',
    家運: '家庭之主有屈於下風，被內助壓迫者，亦常生反彈，吵架滋生。為黑暗時期，宜忍辱負重，期待黎明到來。若不謹守正道者，有失和、破兆也。',
    胎孕: '胎安。將來勞碌命格。',
    子女: '勞苦之命，但行為端正者，終可得福也。',
    週轉: '求人不如求己，凡事需量入為出。若為女色破財，當然求助無門。',
    買賣: '不能如願，有挫折。',
    疾病: '為下腹部之病。口、股之外傷，其病可治。',
    等人: '受到阻礙，不來或遲到。',
    尋人: '途中可遇．來者自來也。',
    失物: '遺失之物不出其門，或存箱籠之中，但難找。',
    旅行: '不利。不宜遠行。',
    訟詞糾紛: '防牢獄枷鎖之災。故凡事宜得過且過，太計較不利也。',
    求事求職: '不得時亦不得意，再待時機。',
    考試: '不理想。',
    改行: '改行不宜。開業者須再待時。',
  },
  井: {
    主要: '表小水之源，有格局小卻能過活之心態。主小吉象。井水雖不能種植、畜養，卻能養人度日，顯示心力之有限，不能做太大的計畫。也另表示視野沒有很寬廣，比較短視，保守心態重。',
    特性: '積極，主動，創新，改革，反應快，口才佳，組織能力強，有領導才能，貴人多助，社會名譽佳。',
    解釋: '井底之蛙。',
    運勢: '缺乏衡力，因井為靜而不能移之物。故凡事已無法進取，不如守之泰然。',
    愛情: '任其自然發展，但情況不怎麼好，有破裂之相。',
    家運: '諸事宜心平氣和處理，守其井水，供來往人用，為守舊之義。諸事宜防有變。',
    胎孕: '宜防有不成之兆。防產母有災，作福祈保可平安。',
    子女: '兒女有順良之象，宜防血光。',
    週轉: '無望。再待時另調。',
    買賣: '尚可，但不可大作為。小交易有利。',
    疾病: '腎、膀胱、尿道等生殖器官之疾。',
    等人: '儘速再連絡，遲來。',
    尋人: '東南或北方可尋。',
    失物: '在室內，可尋，但難尋。',
    旅行: '不宜。',
    訟詞糾紛: '有三四人牽連。不動產之訟宜和解，有刑戮之象。慎之。',
    求事求職: '安於本份，換新的工作也是小工作。',
    考試: '尚可。',
    改行: '改行不利，守之尚可。開業者不宜，此卦有破損之象。',
  },
  革: {
    主要: '表該改革、革新之時候了！主是個狀況卦，吉凶未定。事事情況雖不穩定、明朗，但只要有心改變，重新再來則成功機會大。一切不可固執不化、不變通。',
    特性: '性急，反傳統，愛改變現象，創新事物，旅行，變換工作，口齒伶俐。',
    解釋: '革舊佈新。',
    運勢: '不穩定，多變化之際，凡事均有所變動，故需棄腐朽而立新者，宜下決心改革。但仍須謹慎改革之道，善改則吉，惡改則凶。',
    愛情: '去者已矣。凡事均有新的開始，迎新為宜。',
    家運: '多事之秋。慎重改變自己的生活方式，方能建立新的氣象。',
    胎孕: '胎安。兒女越年長越好，因其運格、際遇常有變動之故也。',
    子女: '子女與母親有緣薄之象。',
    週轉: '要即時改變方針。',
    買賣: '改變經營方式為宜。',
    疾病: '病情變化多端，須轉換求醫。患者為心臟、眼、口及呼吸系統之疾病，可能需要手術去除病灶才能安定。',
    等人: '因中途變卦不會來。',
    尋人: '速改變方向，向西、南方尋找。',
    失物: '遺失難尋。宜向西南或西方或許可以找到。',
    旅行: '可以。但會改變日期。',
    訟詞糾紛: '宜和，有小人。以和順態度處理可調。',
    求事求職: '不可守舊，改變職事有利之時機也。宜速把握良機。',
    考試: '越來越好。',
    改行: '改行大吉大利。開業亦吉。',
  },
  鼎: {
    主要: '表三足而立，三人而行，平穩之勢。另表拜神、禮佛的香爐。主吉象。事業投資應與人合夥為佳，諸事可在平穩中發展。唯對感情、婚姻略有凶象，出現三人行的機率很高，有外遇出軌的跡象。只是大家會很理智的來尋求解決。',
    特性: '因人成事，得利於他人之助，易生多有長輩照顧提拔。交遊廣闊，喜歡熱鬧，人多之處更能表現其才能。',
    解釋: '極為旺盛。',
    運勢: '能解決困難，取得事物之穩定而名利雙收，會有不錯的成就，最好新找外人來共事、合夥。',
    愛情: '吉中帶凶，外表平穩順利，可能另有第三者的介入，且在暗中發展不易被發現，需小心或心裡有準備。',
    家運: '生平幸福之運，吉也。',
    胎孕: '不安，不久便產。男女將來皆豪傑，母宜保。',
    子女: '子女均才能出眾，將成大功立大業之趨勢也。',
    週轉: '可成。',
    買賣: '有大利可得。',
    疾病: '為消化器官腸的病症，應無大礙。需注意心臟之症、心火過旺。有變，但可無礙。',
    等人: '一定會來。',
    尋人: '南方或東南方。不必操心，會自己回來。',
    失物: '東南方尋。',
    旅行: '有意外收穫，可行。',
    訟詞糾紛: '此糾紛由小是非而起，宜和解。',
    求事求職: '大利。有上輩提拔。',
    考試: '登科上榜。',
    改行: '改行有得天獨厚的條件，速進行。開業為吉利通達之象。',
  },
  震: {
    主要: '表奮發、震動有衝動不安穩的現象。六沖純卦，主大好大壞之卦象。此卦要注意意外血光，有被驚嚇之情形發生。運動比賽可為吉論。雷通常表示，聲勢浩大，卻是有聲無形虛象之意。與人交往最忌此卦，表面熱心卻只是虛應一番，無誠心，不能用真誠來對待，否則會很失望。',
    特性: '心直口快，喜怒形於色，缺乏耐性，工作不穩定，愛變化，理財能力差，自立自強。',
    解釋: '受驚，害怕。',
    運勢: '表面似盛泰，但正處於多事之秋，宜慎重，勿自視過高，無益也。有動盪、不安、驚懼之象耳。',
    愛情: '有虛無實，是非、外語雜多。',
    家運: '家中常有變動驚懼、不平等、多爭吵，影響家運進展，宜審慎處理家務事也。',
    胎孕: '臨產婦得之無礙。',
    子女: '子女多有性剛者，但皆爭氣，奮發，並有少年勞苦老來福之象，有成有福。',
    週轉: '抱持可有可無之心態，因此卦有被反悔之象。',
    買賣: '不可有始無終，否則難成。',
    疾病: '所患是腦部、神經系統，或急性肝病、足部之病，病有不安之象，需注意意外血光。等人',
    尋人: '此人臨時起意而出走，但亦會馬上回來。',
    失物: '往東西兩方尋可見，若不見則失矣。',
    旅行: '吉利。臨時起意也。',
    訟詞糾紛: '和勸反覆，但無大害。速調免禍，木字姓的人為貴人。',
    求事求職: '不利。',
    考試: '可榜登科甲。',
    改行: '改行吉利，為變勁之時機也。開業者可做策劃進行。',
  },
  艮: {
    主要: '表停止、退守之意。六沖純卦。主大好大壞之象。凡事應當知進退、量力而為。有如登山越嶺般，需充分審視自己的體能，和山上的情勢，絕不可強行逞能，適時的休息，方能平安度過。得此卦時應有大事或障礙、不順阻擋在前，若能保守、修身養性則安，反則為凶。',
    特性: '耐性佳，保守經營，努力奮鬥，老成持重，有偏財運，精於計畫分析，處事有條理，公私分明，擇善固執。',
    解釋: '不動，自己改變。',
    運勢: '應當潔身自愛，依賴心不要太重，否則不利。凡事不可輕舉妄動，諸事宜守，相輔得吉。儒家有言:『靜亦定，動亦定』，此非言死等，宜中正德行，固守貞常之道，凡事有定之理也。',
    愛情: '有對峙、單戀、難合之勢，雙方各有阻撓也。:『靜亦定，動亦定』，此非言死等，宜中正德行，固守貞常之道，凡事有定之理也。',
    家運: '不和，家運停滯不發，改正自己以謀求開運之道，內有動盪、困境。',
    胎孕: '難產之虞。',
    子女: '子女多有不和、不相輔之數。',
    週轉: '難成。',
    買賣: '有些小是非，失利之象，但可成。',
    疾病: '為鼻炎、口鼻之症，動脈血管硬化或胃膽之疾。難治。',
    等人: '不會來。',
    尋人: '西南方，難尋。',
    失物: '東北方可尋。',
    旅行: '不利。',
    訟詞糾紛: '因小變大。宜速和解，否則有不利之象。',
    求事求職: '固守本份為宜。',
    考試: '落榜。',
    改行: '改行不利。開業不宜，須再待時。',
  },
  漸: {
    主要: '表循序漸進，不可心急之意。主吉象。好事慢慢在進行中，一切遵循正裡常規即可，事業投資均能有收益。感情婚姻，更是結果收成歡喜結局之時。',
    特性: '感覺細膩，思想敏銳，重品味，講情調之人，愛追求變化的事物，口才佳。反應快，為人熱心，財運豐厚。',
    解釋: '逐漸好轉。',
    運勢: '逐漸順利，光明開運之象。凡事物務必掌握時機，循序漸進，可得吉慶。須防款項交易之差錯及色情之災。',
    愛情: '慢慢交往可成就良緣。但此卦有女子思男或已入男家，未能得男方家長同意者，但有心中能成良緣。',
    家運: '漸曙光明、幸福之象，諸事宜以順乎自然為吉，反則有剋也。',
    胎孕: '無礙。',
    子女: '兒女多堅強篤實，態度溫順，將來有成就。',
    週轉: '不成亦勿放棄，久調可成。',
    買賣: '漸進有利，過急不利，欲速則不達。',
    疾病: '為胃腸、耳鼻之症，耐心治療可漸癒。',
    等人: '遲到。',
    尋人: '在東南或東北二方，過些時日可尋得。',
    失物: '遺失可尋。',
    旅行: '可，但可能會誤期。',
    訟詞糾紛: '進則勝，退則輸。買賣是非居多。',
    求事求職: '可尋得良好工作。',
    考試: '金榜題名。',
    改行: '改行吉利。開業吉利，漸有發展之象。',
  },
  歸妹: {
    主要: '表小妹急著先出嫁，為感情衝動、不理智之象。主小吉帶凶。得此卦投資、升遷、合夥，大概與男女之間的感情用事，會有很大的關連。感情，第三者積極介入之象，或是自己一廂情願衝動的妄想。',
    特性: '具有同情心，喜歡結交朋友，愛熱鬧，貴人運多，戀愛運早，喜追求變化性工作，容易轉換工作，多學不專。',
    解釋: '游魂掛、精神恍惚。',
    運勢: '禍出百端，事物有違常理。初時有悅，不久反凶，禍害隨至。',
    愛情: '孽緣，必以悲劇收場。',
    家運: '外觀風光幸福，其實家內正起風波，有失和、禍害等不幸情況。',
    胎孕: '無礙。秋占不利。',
    子女: '兒女有沉於情慾之中者。',
    週轉: '希望渺小。',
    買賣: '表面上不錯，其實虧了老本。',
    疾病: '為肝病、腫瘤、骨髓系統或腦溢血、手腳受傷。',
    等人: '不會來。',
    尋人: '在東方或西方。',
    失物: '監守自盜，內神通外鬼、盜走。',
    旅行: '不宜。',
    訟詞糾紛: '有女人在內，事求分曉後，力持和解，不必要無謂鬧事。',
    求事求職: '放棄算了，再另做打算。',
    考試: '有重新再讀一年之況。',
    改行: '改行不宜。開業者宜暫停，靜候時機。',
  },
  豐: {
    主要: '表豐收之象。主吉中帶小凶之象。凡事積極奮發可成，有興致高昂，一時天雷勾動地火，閃電迅速達成之意，此卦最利於短期投資理財，感情則可情投意合而速成。',
    特性: '隨和謙虛，懶散但內心急躁，被動，拖延，須人催促，多偏財運或意外之好處，及異性之幫助。',
    解釋: '豐富，豐收。',
    運勢: '豐者必有所得，明智者宜於最全盛有收穫時，保身明哲。然人多貪而不厭，於滿足，而挺險圖利，終有悔。尤其應注意有訴訟之事，有損財或火災之象。',
    愛情: '熾情，好動，情可成。若得意忘形則有失。',
    家運: '繁華幸福，但為人處事宜守分寸為要。',
    胎孕: '無礙。',
    子女: '對子女宜多加教養，免招不幸。',
    週轉: '耐心應對，則可在短時間內達成。利在寅午未申日。',
    買賣: '正直經營可獲利。貪則有失。',
    疾病: '為急性病，需注意是心臟病或意外傷害所造成。神經系統可能受傷的疾病，小心手足骨折、殘廢。',
    等人: '會來。亦會因故而很快離去。',
    尋人: '此人會自覺不安而自己回來。',
    失物: '往東、南方尋可得。',
    旅行: '雖宜但有些波折。',
    求事求職: '有利。職務工作變動大，或外務之類。',
    考試: '及格有望。',
    改行: '改行不可三心二意。開業者吉利。',
  },
  旅: {
    主要: '表旅行、不定、不安穩之意。主小凶帶小吉之象。旅行者常居無定所。表示事事皆在浮動之中，雖不現凶象，但也是很令人煩心的。投資理財、感情、婚姻，大慨都是遊戲的心態吧！',
    特性: '一生多外出旅行運，喜歡多變化事物，重效率，享受，亦多桃花運，異性緣濃。興趣廣泛，愛嘗試冒險。',
    解釋: '在火山口旅遊，非常危險。',
    運勢: '諸事不定，宜考量他人之意見。潔身自愛，始可改變厄運，否則必有凶。',
    愛情: '不穩定、遊戲心態重，宜以平常心看之，不能太重視。',
    家運: '內面不和，是非多，意見不一。家運衰也。',
    胎孕: '多是夜間產。',
    子女: '骨肉無情，不幸之兆。',
    週轉: '小數目可，大數目不成。',
    買賣: '多礙難成，或交易不成。',
    疾病: '是心臟與消化系統脾膽之疾，心臟、手部之傷。有多病轉移之象，宜速求醫，拖延難治。',
    等人: '臨時變意不會來。',
    尋人: '此人為情所困，意志消沉而遠離也，難尋。',
    失物: '被盜走，找不回也。',
    旅行: '不宜。',
    訟詞糾紛: '拖延時間不利，難解決。若和解不成，難脫伽鎖之患。',
    求事求職: '白費心神。',
    考試: '很差。',
    改行: '改行不利。開業者不得時機。',
  },
  巽: {
    主要: '表不定之象，時柔順、時狂暴。六沖純卦，主大好大壞之象。不易控制的局面，包括人、事、感情、投資，都是令人很難去掌控的、會有波折和變化。',
    特性: '求知慾強，喜歡追求新事物，新潮流，多外出旅行運，朋友多，財運佳，異性緣重，相識滿天下。',
    解釋: '粗暴。',
    運勢: '此卦象徵多波折，游離不定之運也。此時要處理不驚，堅定意志，物事果斷而隨機應變，不必一味跟從順行。待人接物宜心平氣和，則可得意外之收穫。切記狂風之下必有所損。',
    愛情: '要冷靜。風若和，日必麗。風若凝聚，可知暴風雨將至，終身大事不可不慎。時好時壞，把戲真不少，均輕飄不實也。',
    家運: '家內已生風波，遵照長輩意見與指示方可渡過難關，否則動盪不安。',
    胎孕: '有礙。宜慎。',
    子女: '養兒育女，可謂無微不至，但有孝順恩情之子女，福也。',
    週轉: '小吉，大則難調。',
    買賣: '好時機，絕對有利。',
    疾病: '內症是糖尿病或胃腸之症。病症在外表大是在皮膚病上，病情變化無常，宜長期調理。',
    等人: '主動約人，有結果。',
    尋人: '此人隱於附近朋友家中，好言相勸可回。大致是吵架出走，若是債務糾紛尋人者，注意發生衝突損傷。東南方位。',
    失物: '東南方可尋。混藏於其物品下面。',
    旅行: '可。',
    訟詞糾紛: '不易和解，託地方人士調解，二人可調。',
    求事求職: '不易和解，託地方人士調解，二人可調。',
    考試: '宜多用功。',
    改行: '改行需看情況，但必有所變動。開業可行，但勿太急，慢慢進取有利。',
  },
  兌: {
    主要: '表少女純真喜悅之象。卻在純真之中帶有嬌蠻、任性的態度。六沖卦象，大好大壞。憂喜參半！',
    特性: '細心，體貼，善解人意，口才佳，幽默感，宜從事公關，服務業。',
    解釋: '喜悅，高興。',
    運勢: '有喜亦有憂，有譽亦有譏，雖得吉慶如意，然應守持正道，否則犯災。',
    愛情: '有嬌蠻、任性鬥嘴之象，切勿意氣用事，因此卦內剛，定會有口舌，隨和相處可吉。有如兩小無猜的純純感情般。',
    家運: '有和悅之氣象，但要操守自律，行事不可越軌，有分寸可得吉運。若不操守自律，必犯色情之害而受殃耳。',
    胎孕: '孕安。能帶給家人喜悅，又與六親和睦，有緣。但也不要過份溺愛才是。',
    子女: '骨肉情深，和好幸福之象。',
    週轉: '可順利，不須急也。',
    買賣: '有反覆之象，然盡力必成，可得大利之交易。',
    疾病: '酒色之病，久則難癒。',
    等人: '會來，且有喜訊相告。',
    尋人: '很快可知其下落。向西方尋可得。',
    失物: '在西方，喜中有失，詢老者可得。',
    旅行: '鼓勵。',
    訟詞糾紛: '非大事，有人解說有利。',
    求事求職: '得利，但亦不可太大意。',
    考試: '成績佳。',
    改行: '改行吉利，開業吉利。',
  },
  渙: {
    主要: '表渙散之象。主凶象。心神不寧、精神不佳，人事向心力均已離散，有頹廢不振的運勢。事業、感情、婚姻根基開始有鬆動的情勢產生。人的心緒、沈悶是最大的影響。若能穩定情緒、定下心神，應會再恢復以往的神采風光。',
    特性: '喜追求變化，缺乏耐性，工作不穩定，理財能力差，愛旅行外出，變換住所。對朋友熱心，桃花運也多。',
    解釋: '煥然一新，渙散。',
    運勢: '初有小損，但終可解困而心悅，凡事小心則百事亨通，任性放縱必敗。遠方物事吉利，但宜守其常。',
    愛情: '因有一些小障礙，而可能會造成離散之象，外在因素，情緣仍在，有可能在復合。',
    家運: '起初有損，有波折且困，但可漸入佳境。',
    胎孕: '臨產婦無礙。',
    子女: '雙親百般勞苦為子女，而子女深知父母養育教導之恩，多得良好配偶，並有才藝之象。',
    週轉: '目標準確則有利，否則波折多亦難成。',
    買賣: '雖難成，客戶會再回來，但忍耐些時日可有利。',
    疾病: '幼年體弱多病，多有逐年漸康復，但體質亦弱。病大致凶，須依病情之新舊論斷。因此卦雖病不穩定，有險象，但亦有解。',
    等人: '不一定會來。',
    尋人: '難尋。相約者前者未定，後者可相尋，有信即可速動。',
    失物: '散失，難尋。得亦有損。',
    旅行: '吉。但勿近水。',
    訟詞糾紛: '宜緩，待成見已散時，再調可成和解。',
    求事求職: '雖有挫折，但可成。宜耐心。',
    考試: '尚可，宜再加油。',
    改行: '擇時視勢而行，不必急於一時。開業可，初時有小挫折，勿驚。',
  },
  節: {
    主要: '表節制之象。主是個狀況卦，吉凶未定之象。卦中所現，提醒卜卦之人，事事有過於放縱之象，尤其是在金錢方面，投資理財需節制有守為安。感情則要多留意自己的言行舉止，已有過份之態或對對方過於放縱之勢 。',
    特性: '理想高遠，但與現實差距大，不過卻有恆久的耐力，有志者是竟成。寬以待人，嚴以律己，社會聲譽不錯。',
    解釋: '節制，節約。',
    運勢: '以和悅之心去渡過險境，則可克難而出險道。而悅者何苦惱，陷者有悅象，故此時需要有節制為宜。',
    愛情: '是否成就，須看自己之節制之品德，因得此卦，若女者對感情堅貞而不隨便者，可吉也，否則破。',
    家運: '謹守做人處事的規範，則可得平安和樂之家庭。',
    胎孕: '可求神保產母，否則有厄。',
    子女: '兒女多溫柔孝順。但若長輩行為不檢反招破，得不償失也。',
    週轉: '遠水難救近火。無望。',
    買賣: '不得時也。',
    疾病: '為腎臟系統或肺部系統等疾病，病情拖延有險象。',
    等人: '不來，或須久等。',
    尋人: '人說皆是虛言。有所藏身，不必去尋。想回來自己會回來。',
    失物: '西方或西北方，雖在近處，但難尋。',
    旅行: '不宜。',
    訟詞糾紛: '此糾紛有拖拖拉拉之象，莫論誰是誰非，耐心處理必有人調解。',
    求事求職: '再待時機，成功率小。',
    考試: '須力求上進，不可怠忽。榜尾有名。',
    改行: '改行不宜。開業宜緩圖，計劃週詳後方可。',
  },
  中孚: {
    主要: '表誠信、實在。主吉象。若為人處事一切以誠信為重，則事事皆可順利而行。',
    特性: '心地善良，負同情心，朋友多助，為人風趣，口才佳，反應快，桃花運早。重羅曼蒂克情調，講氣氛感覺。',
    解釋: '得人緣，孚眾望。',
    運勢: '如三月之春花，似錦大地，與人謀事均得利。誠者，立業之本也，若存邪曲之念則破吉運，須認清善惡。得此卦誠者佳，尤利他鄉財路。',
    愛情: '雙方以誠相待，良緣也。',
    家運: '有突發大利，平安幸福之吉象。',
    胎孕: '安。',
    子女: '父母慈且誠信，故子女必孝，忠信門第也。',
    週轉: '不成問題。',
    買賣: '可成亦有利。',
    疾病: '病情大致凶，為胃腸、腹部或腎弱之症，宜妥善調理。',
    等人: '必定來。',
    尋人: '不尋自來。',
    失物: '遺失難尋，雖有消息亦虛耳。',
    旅行: '吉利。',
    訟詞糾紛: '為安來之事，速處理無妨，遲則有傷。更防盜賊。',
    求事求職: '有利。',
    考試: '必定上榜。',
    改行: '改行可按照計劃行事。開業者吉利，先苦後樂之象。',
  },
  小過: {
    主要: '表有志難伸、龍困淺灘，像犯了一個小的過錯般，而被處罰。主凶象。事事小有不順，有被打壓的跡象，最忌衝動犯上，必因此而埋下禍端。需沈潛、忍耐一段時間才能再有發揮的機運。',
    特性: '謙虛，敬業，主動，積極，反應快，律己巖，不斷吸收新知識，學習新事物。樂於助人，勇於認錯。',
    解釋: '稍有過失。',
    運勢: '行事不如意，宜謹守，與六親不和之象。又忠言逆耳犯上得罪人之事，反為害己之因，故凡諸事均有枝節、苦煩，不能達志耳。',
    愛情: '擁有過，但自覺不相稱，已經不掛於心矣。',
    家運: '常生口角，有離家去外地之象。此卦名小過，故有因過而須離鄉耳。',
    胎孕: '須謝灶神方可得安順。',
    子女: '骨肉無情，兄弟不和，多勞苦之象耳。',
    週轉: '小有望，大難成。',
    買賣: '小可，大不可。因過者必有錯也。',
    疾病: '患手、足之患，審察之。病情雖有變惡之向，但治癒有望，不可掉以輕心。',
    等人: '不來，否則會遲到。',
    尋人: '只在原處逃避，當得見。如有債務之爭，別找也罷，恐有過失之錯。',
    失物: '可能遺失在水邊，難現。',
    旅行: '不利。',
    訟詞糾紛: '爭端無益。宜和。',
    求事求職: '無指望。',
    考試: '不理想。',
    改行: '改行不宜。開業者宜再待時機，否則不利。',
  },
  既濟: {
    主要: '表水火陰陽調和，一時平安和樂之象。主吉中帶凶之象。水火本相剋，因一時環境或人事所影響，而暫時相安無事，但終非常久之象，必有所沖剋而敗。',
    特性: '物質豐盛，但精神易空虛，外華內虛之感，交際廣，知心少，心思細膩，多愁善感，喜寧靜的生活。',
    解釋: '已經得到利益不可貪之無厭。',
    運勢: '功名雙收。極盛既至，但須知物極必反，宜退守為吉，再進則凶。但得此卦者，皆有因一時順利而忘本，大意失荊州，終有人不和、心迷亂之象。',
    愛情: '起初出雙人對，形影不離，久後反無情必散。',
    家運: '生在富家之象，受祖上澤恩而不知珍惜者多，終而破運之兆。',
    胎孕: '不安。',
    子女: '初時幸福，長大後與雙親不和，慎之。',
    週轉: '短期可成，長期無望。',
    買賣: '中途變卦不利。故初時見好就收，莫貪心拖延，否則不利。',
    疾病: '為心臟、腹部、腎臟等之疾病或老人疾病。有一時好轉，隨之病重，凶象也。',
    等人: '已中途折回，不必再等。',
    尋人: '若是常出走之人，小時自回，長大再出走，難尋。甚或不回。',
    失物: '尋得又失。同事人見可去尋堆積處，或勿再尋。',
    旅行: '宜近不宜遠，否則有災。',
    訟詞糾紛: '有理變理虧，有虛驚，宜和解。',
    求事求職: '濟急可，不宜良久。',
    考試: '實力有，但需視考期之日吉凶而定，小吉。',
    改行: '改行不宜。開業者最好慎重考慮，因此卦象好景不常。',
  },
  未濟: {
    主要: '表陰陽不調和，氣血不順。主凶中帶小吉之象。人事情緒不順應是最大的影響主因。幸好此卦會有否極泰來之變化，一切均會雨過天清的。值的等待。',
    特性: '思想高遠，又缺乏耐性，神經質，情緒不穩定，內心易有衝突感，孤獨感，悲觀，感性。宜往藝術哲學宗教發展。',
    解釋: '快要獲利。',
    運勢: '氣運不通，諸事不能如願，有初衰後盛之數。凡事要有耐心去突破難關，前途將大有可望。此時宜採取篤實的方針和毅力，能夠努力建立自己者，可名利雙收。與人共謀事者，終有大利，可為之。',
    愛情: '剛開始時意見不能溝通，格格不入。若能試著去接受對方，可成也。',
    家運: '初運不振，耐心熬過辛苦的路，可得幸福。',
    胎孕: '平安。',
    子女: '子女遲得，但終有繁榮之象，將來都能振奮家聲，以慰祖德。',
    週轉: '有不少困難，成敗各半。',
    買賣: '初限不佳，漸有好轉之象。',
    疾病: '為下腹部與血液循環之症。',
    等人: '雖遲到，但有吉兆。',
    尋人: '負氣出走，會自己回來。',
    失物: '在香火佛堂或水邊不遠處或混在某物中。北或南向可尋。',
    旅行: '有意外而影響行程，不宜遠行。近可。',
    訟詞糾紛: '就算要和解也煞費周章。拖些時日有貴人化解。',
    求事求職: '枉費心機，無希望，再等候時機。',
    考試: '不錯，有好成績。',
    改行: '改行可進行。開業吉。勿因初時不佳而灰心，生意會轉興隆。',
  },
};
